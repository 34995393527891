.Qema {
  padding: 0 0 60px 0;
}
.QemaContainer {
  padding: 20px 15px 80px;
  position: relative;
  border-radius: 12px;
  background: #ecf7ff;
}
.firstIcon {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: -1;
}
.lastIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.QemaContainer h2 {
  color: var(--bg-color, #0a2640);
  font-size: 40px;
  font-weight: 700;
  line-height: 257%; /* 102.8px */
}
.QemaLine {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.QemaTxt {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.QemaTxt span {
  color: var(--bg-color, #0a2640);
  font-size: 20px;
  font-weight: 700;
  line-height: 2rem;
}
.QemaTxt p {
  margin: 0;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 2rem;
}
.QemaLine:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .QemaContainer h2 {
    font-size: 24px;
  }
  .QemaTxt {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .QemaTxt span {
    color: var(--bg-color, #0a2640);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }
  .QemaTxt p {
    margin: 0;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
  .QemaLine {
    align-items: flex-start;
    gap: 16px;
  }
  .faqs {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)) !important;
  }
}
