.notesAbout {
  padding: 80px 0;
}
.notesAbout .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.NotesCard {
  border-radius: 10px;
  background: #ecf7ff;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.05);
  /* min-width: 308px; */
  height: 190px;
  overflow: hidden;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.NotesCard:first-child .iconNotes {
  border-radius: 10px;
  background: #ffd4d4;
  padding: 10px;
}
.NotesCard:nth-child(2) .iconNotes {
  border-radius: 10px;
  background: #d4f5ff;
  padding: 10px;
}
.NotesCard:nth-child(3) .iconNotes {
  border-radius: 10px;
  background: #ffeed4;
  padding: 10px;
}
.iconNotes img {
  width: 40px !important;
}
.NotesCard h3 {
  color: var(--bg-color, #0a2640);
  font-size: 16px;
  font-weight: 700;
  line-height: 137.3%; /* 21.968px */
  letter-spacing: 0.88px;
  text-transform: uppercase;
}
.NotesCard h4 {
  color: var(--bg-color, #0a2640);
  font-size: 15px;
  font-weight: 300;
  line-height: 145.3%; /* 21.795px */
  letter-spacing: 0.3px;
  width: 275px;
}
