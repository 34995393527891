.Feedback {
  padding: 80px 0;
  background: linear-gradient(126deg, #013 31.92%, #00122c 71.22%);
  position: relative;
}
.oval2{
  position: absolute;
  top: -100px;
  right: 0;
}
.oval5{
  position: absolute;
  bottom: -28%;
  left: 50%;
  transform: translateX(-50%);
}
.MainHeadFeedback h2 {
  color: #fff;
}
.MainHeadFeedback span {
  color: #a9a9a9;
}
.FeedbackList {
  padding: 30px 50px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 30px 80px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 1;
}
.oval3{
  position: absolute;
  bottom: -80px;
  right: -10px;
}
.FeedbackCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid #F3F4F6;
  padding: 20px 0
}
.feedbackHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.feedbackHeading_name h2 {
  color: #261e27;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.feedbackDescription {
  color: #5b555c;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  padding-right: 15px;
}
