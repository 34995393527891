.swiperContainer {
  min-height: 80vh;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.oval4 {
  position: absolute;
  top: -150px;
  left: 0;
  /* z-index: -1; */
}
.swiperMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 250px;
  height: 500px;
  margin: 0 auto;
}
/* .handScroll {
  position: absolute;
  z-index: 1000;
  background: #000;
  height: 100%;
  opacity: 0.5;
  top: 0;
  border-radius: 35px;
} */
.handScroll {
    position: absolute;
    z-index: 1000;
    background: #000;
    height: 100%;
    opacity: 0.5;
    top: 0;
    border-radius: 35px;
    pointer-events: none; /* هذا يجعل العنصر غير قابل للتفاعل معه */
  }
.handScroll > div{
    height: 100%;
}

.iphone-frame {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #fff;
}
.iphone-content {
  position: absolute;
  top: 3%;
  left: 7%;
  width: 86%;
  height: 93%;
  z-index: 2;
  overflow: hidden;
}
.mySwiper img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background: transparent;
}
.bgmob {
  position: absolute;
  z-index: -100000;
}
