.location {
  margin: 60px 0;
}
.location .container {
  background: #e7e7e7;
}
.location .locationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}
.locationContainer .RSideLocation {
  flex-basis: 50%;
}
.searchLocation {
  padding: 20px 0 15px 0;
  width: 100%;
  background: #fff;
  padding: 10px;
  position: relative;
  width: 95%;
  margin-right: 15px;
  margin-bottom: 10px;
}
.searchLocation input {
  width: 80%;
  border: none;
  outline: none;
}
.searchLocation i {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.listCards {
  max-height: 80vh;
  overflow-y: scroll;
}

/* Width and Color */
.listCards::-webkit-scrollbar {
  width: 2px; /* adjust as needed */
}

.listCards::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the thumb */
  border-radius: 6px; /* roundness of the thumb */
}

/* Track */
.listCards::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

/* Handle on hover */
.listCards::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the thumb on hover */
}
@media (max-width: 994px) {
  .locationContainer {
    flex-direction: column-reverse;
    gap: 30px !important;
  }
}
