.fav_articles{
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    padding: 20px 10px 60px 10px;
    width: 100%;
}
.fav_articles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* gap: 50px; */
}
.fav_articles_head{
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    padding: 25px 0;
}
.fav_articles_cards{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}
.fav_articles_card{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    border-top: 1px solid #BCBCBC;
    padding-top: 20px;
}
.fav_articles_card .fav_articles_card_img{
    /* width: 44px; */
    height: 81px;
    border-radius: 10px;
    overflow: hidden;
    flex: 1 0 0;
}
.fav_articles_card .fav_articles_card_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fav_articles_card h3{
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    flex: 1.5;
}
.follow_us{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    padding: 30px 0;
}
.follow_us h3{
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.follow_us ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.follow_us ul a{
    text-decoration: none;
    color: #fff;
    background: var(--bg-color);
    border-radius: 50px;
    /* width: 24px;
    height: 24px; */
    padding: 10px 10px;
}
.follow_us ul a i{
    font-size: 18px;
}
@media(max-width:994PX){
    .AdsSide{
        margin-top: 20px;
    }
}