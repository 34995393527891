@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@300;400;500;600;700;800;900&display=swap');
:root{
    --color-primary: #331B3B;
    --color-text: #fff;
    --bg-color: #0A2640;
    --bg-color-light: #0A2640E5;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  html{
    font-family: 'Alexandria', sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    vertical-align: middle;
    color: var(--color-text) !important;
  }
  body{
    font-family: 'Alexandria', sans-serif !important;
    direction: rtl;
    /* overflow-x: hidden; */
  }
  ul{
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
  }
  h1,h2,h3,h4,h5,h6{
    margin: 0;
  }
  