.CommonLocation {
  background: url("../../../assets/images/locationHero.jpeg");
  background-position: center;
  background-size: cover;
  padding: 130px 0 60px;
  position: relative;
  z-index: 1;
}
.CommonLocation::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0d284280;
  z-index: 2;
}
.CommonLocation .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 3;
}
.CommonLocation h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  text-transform: capitalize;
  margin: 0;
}
.CommonLocation p {
  color: #e1e1e1;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px; /* 140% */
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .CommonLocation h1 {
    font-size: 20px;
  }
  .CommonLocation p {
    font-size: 16px;
  }
}
