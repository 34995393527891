.hero {
  background: url(../../../assets/images/heroImg.jfif);
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  /* z-index: -1; */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0a2640e5;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 2;
  padding-top: 150px;
  padding-bottom: 50px;
  /* padding: 64px 0; */
}
.hero .container .heroContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  gap: 3rem;
}
.contentHero h2 {
  color: #fff;
  font-size: 54px;
  font-weight: 700;
}
.contentHero p {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 60px;
}
.hero_img {
  width: 600px;
  /* height: 600px; */
}
.hero_img img {
  max-width: 100% !important;
  object-fit: contain;
}
.downloadBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 55px;
}

.downloadBtns img {
  width: 198px;
  height: 66px;
  max-width: 100%;
  object-fit: contain;
}
@media (max-width: 994px) {
  .hero .container .heroContainer {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .contentHero h2 {
    font-size: 18px;
    text-align: center;
  }
  .contentHero p {
    font-size: 12px;
    text-align: center;
  }
  .downloadBtns {
    gap: 20px;
  }
  .downloadBtns img {
    width: 110px;
  }
  .contentHero p {
    margin-bottom: 0;
  }
}
