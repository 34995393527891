.SendMessage{
    padding-bottom: 60px;
    position: relative;
}
.SendMessage form{
    width: 100%;
}
.SendMessage form input{
    border: 1px solid #E1E1E1;
    outline: none;
    width: 100%;
    padding: 15px 10px;
}
.SendMessage form textarea{
    width: 100%;
    height: 258px;
    border: 1px solid #E1E1E1;
    outline: none;
    padding: 15px 10px;
}
.SendMessage form input::placeholder{
    padding: 10px 0;
    color: #5D5D5D;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
}
.SendMessage form textarea::placeholder{
    color: #5D5D5D;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
}
.SendMessage form button{
    padding: 12px 40px;
    /* width: fit-content; */

    border: none;
    outline: none;
    border-radius: 10px;
    background: #0A2640;

    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
}
@media(max-width:994px){
    .contactImgCol{
        display: none;
    }
}