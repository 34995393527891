.LastBlogs{
    /* padding: 60px 0; */
    /* z-index: 1000000; */
    padding-bottom: 60px;
    position: relative;
}
.LastBlogs .mainHead{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.seeMore a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 8px;
    background: #0A2640;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 13px 20px;
    color: #FFF;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px; /* 184.615% */
    width: fit-content;
}
.seeMore a svg:first-child{
    margin-left: -17px;
}
.seeMore a i:first-child{
    opacity: 0.6;
}
.LastBlogs .container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}