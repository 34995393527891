.ContactCard {
  background: #ecf7ff;
  padding: 40px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ContactCard img {
  width: 30px;
}
@media (min-width: 994px) {
  .ContactCard {
    width: 400px !important;
  }
}
.ContactCard_content h3 {
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 18px;
}
.ContactCard_content p {
  color: #5d5d5d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 0% */
  margin: 0;
}
@media (min-width: 994px) {
  .ContactCard {
    width: 361px;
  }
  .ContactCard_content {
    width: 250px;
  }
  .ContactCard_content p {
    width: 200px;
  }
}
.ContactCard_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #0a2640;
}
.ContactCard_icon i {
  font-size: 24px;
}
