.CardLocation {
  width: 100%;
  border-radius: 12px;
  background: #f2f3f4;
}
.CardsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.CardsList img{
  max-width: 500px;
}
.CardLocation {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 40px;
}
.CardLocation_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CardLocation_title {
  color: #000;
  text-align: right;
  font-family: Alexandria;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.CardLocation_header button {
  border: none;
  outline: none;
  border-radius: 12px;
  background: #0066de;
  padding: 10px 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
}

.CardLocation_body {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 40px;
}
.CardLocation_body_info h3 {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.mobileList {
  color: #5d5d5d;
  font-size: 14px;
  font-weight: 400;
  /* display: flex;
  align-items: center; */
}
.mail{
    position: relative;
}
.mail::before{
    content: '';
    position: absolute;
    top: 0;
    right: -16px;
    height: 100%;
    width: 2px;
    background: #D9D9D9;
}

@media(max-width:767px){
 
  .mail::before{
    display: none;
  }
  .CardLocation_title{
    font-size: 16px;
  }
  .CardLocation_header button{
    padding: 8px 6px;
    font-size: 12px;
  }
  .CardLocation_body_info h3{
    font-size: 14px;
  }
  .mobileList{
    font-size: 12px;
  }
}