.BlogCardList .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.BlogCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}
.BlogCard a {
  text-decoration: none;
}
.BlogCard_img {
  width: 100%;
  height: 240px;
  border-radius: 16px;
  overflow: hidden;
}
.BlogCard_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.BlogCard_content .BlogCard_content_date {
  color: #0a2640;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
  display: block;
}
.BlogCard_content h4 {
  color: #0a2640;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  text-transform: capitalize;
  padding: 0 0 0 100px;
}
.BlogCard_content p {
  margin: 0;
  overflow: hidden;
  color: #475467;
  text-align: right;
  text-overflow: ellipsis;
  font-family: Alexandria;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding-left: 20px;
}
.BlogCard_content a {
  text-decoration: none;
  color: #0a2640;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  border-radius: 9999px;
  border: 1px solid #b7bbdb;
  background: #f0faff;
  padding: 4px 10px;
  display: block;
  width: fit-content;
  margin-top: 24px;
}
@media (max-width: 994px) {
  .BlogCardList .container {
    flex-direction: column;
  }
}
