.ContactInfo {
  padding: 80px 0;
}
.ContactInfo .container {
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
}
.ContactInfoContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.MainHeadContact {
  color: #000;
  font-size: 34px;
  font-weight: 500;
  line-height: 28px; /* 82.353% */
  text-transform: capitalize;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .MainHeadContact {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
