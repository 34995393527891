.AdvantageDownload {
  padding: 80px 0 0 0;
  position: relative;
}
.MainHeadDownload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}
.ovalImg {
  position: absolute;
  width: 100%;
  top: -100px;
  right: 0;
  /* z-index: -1; */
}
.ovalImg img {
  width: 100%;
}
.circles {
  position: absolute;
  bottom: -150px;
  width: 100%;
  z-index: -1;
}
@media (min-width: 994px) {
  .ovalImg {
    display: none;
  }
}
