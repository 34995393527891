.ReviewCard{
    border-radius: 18.118px;
    background: #ECF7FF;
    backdrop-filter: blur(5.5px);
    padding: 40px 20px 20px 20px;
    position: relative;
    transition: all .3s;
    /* width: 334px; */

}
.ReviewCard_img{
    width: 66px;
    height: 66px;
    border-radius: 50px;
    background: #ECF7FF;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    transition: all .3s;

}
.ReviewCard_img::after{
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50px;
    opacity: 0.5;
    background: lightgray 50% / cover no-repeat;
    filter: blur(6.5px);
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
.ReviewCard_img img{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    position: relative;
    left: 13px;
    top: 5px;
    z-index: 2;
    object-fit: cover;
}
.ReviewCard_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
}
.ReviewCard_content h4{
    color: #0A2640;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
}
.ReviewCard_content p{
    color: rgba(51, 27, 59, 0.66);
    font-size: 15px;
    font-weight: 300;
    line-height: 145.3%; /* 21.795px */
    letter-spacing: 0.3px;
    margin: 0;
}
.ReviewCard:hover{
    background: #0A2640;
}
.ReviewCard:hover .ReviewCard_img{
    background: #0A2640;
}
.ReviewCard:hover h4, .ReviewCard:hover p{
    color: #fff;
}
.rateStars svg:not(:last-child){
    margin-left: 5px;
}
.ReviewList{
    margin-top: 60px;
    width: 100%;
    height: 100%;
}
/* .ReviewList .container{
    width: 100%;
    height: 100%;
} */
.ReviewList .react-multi-carousel-track {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    padding-bottom: 30px;
    position: relative;
    /* gap: 60px; */
}

.ReviewList .custom_btn_left,.ReviewList .custom_btn_right{
    bottom: 100px;
    border: none;
    outline: none;
    background: transparent;
    border: 2px solid #000;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    font-size: 20px;
    z-index: 10000 !important;
}
/* .react-multi-carousel-item{
    width: 443px !important;
} */
.ReviewList .custom_btn_right{
    position: absolute;
    right: 42%;
    bottom: 0;
}
.ReviewList .custom_btn_left{
    position: absolute;
    left: 42%;
    bottom: 0;
}
.react-multi-carousel-dot-list {
    bottom: 5px !important;
}
.react-multi-carousel-dot button{
    width: 10px !important;
    height: 10px !important;
    border-radius: 50px !important;
}
.react-multi-carousel-dot--active button{
    width: 16px !important;
    height: 10px !important;
    border-radius: 50px !important;
}
@media(min-width:994px)
{
    .ReviewList .react-multi-carousel-item{
        width: 344px !important;
    }
    .ReviewList .react-multi-carousel-track {
        gap: 90px;
    }
}