.LocationBox{
    padding: 80px 0;
}
.LocationBox .LocationBox_container {
  width: 100%;
  border-radius: 12px;
  background: rgba(10, 38, 64, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding: 40px 100px;
  min-height: 60vh;
}

@media(max-width:767px){
  .LocationBox .LocationBox_container {
    padding: 40px 10px;
  }
  
}