.footerMain {
    padding: 60px 0;
    width: 100%;
    /* height: 100vw; */
    overflow: hidden;
    background: url("../../../assets/images/footerImg.jfif");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
  }
  .footerMain::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(10, 38, 64, 0.8) 0%, #0a2640 100%);
    z-index: -1;
  }
  .footerMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .footerMain .footerHead h2 {
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 106.3%; /* 51.024px */
    letter-spacing: -0.48px;
    margin: 0;
    margin-bottom: 40px;
  }
  .footerMain .footerHead span {
    text-align: center;
    color: #e1e1e1;
    font-size: 16px;
    font-weight: 400;
  }
  .footerMain form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 770px;
  }
  .footerMain form input,
  .footerMain form textarea {
    border: 2px solid rgb(225, 225, 225, 0.4);
    border-radius: 10px;
    padding: 12px 20px;
    background: transparent;
    outline: none;
    color: #fff;
  }
  .footerMain form input::placeholder,
  .footerMain form textarea::placeholder {
    color: rgb(255, 255, 255, 0.4);
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
  }
  .footerMain form input:focus,
  .footerMain form textarea:focus {
    border-color: #fff;
  }
  .footerMain form input {
    width: 100%;
  }
  .footerMain form textarea {
    width: 97%;
    height: 170px;
  }
  .footerMain form button {
    border: none;
    outline: none;
    background: #fff;
    border-radius: 10px;
    padding: 12px 50px;
    align-self: flex-end;
    margin-left: 10px;
  
    color: #0a2640;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
  }
@media (max-width: 767px) {
    .footerMain .footerHead h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .footerMain .footerHead span {
      font-size: 12px;
    }
  }