.DownloadHero {
  min-height: calc(100vh);
  padding: 90px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(126deg, #013 31.92%, #00122c 71.22%);
  position: relative;
  /* z-index: -2; */
}
.DownloadHero_main {
  display: flex;
  align-items: center;
  gap: 100px;
}
.mobileHero img {
  width: 100%;
}
.DownloadHero_text h1 {
  color: #fff;
  text-align: right;
  font-size: 42px;
  font-weight: 600;
  line-height: 150%; /* 36px */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}
.DownloadHero_text span {
  color: #a9a9a9;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%; /* 22.5px */
  letter-spacing: 0.15px;
  text-transform: uppercase;
}
.downloadBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadBtn a {
  text-decoration: none;
  border-radius: 50px;
  border: 4px solid #2e1c4b;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px;

  color: #00122c;
  text-shadow: 0px 4px 25px rgba(30, 215, 96, 0.12);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
}
.centerDevOne{
    position: absolute;
    bottom: -300px;
    left: 0;
}
@media (max-width: 994px) {
  .DownloadHero_main {
    display: block;
    width: 100vw;
  }
  .DownloadHero_main .mobileHero {
    display: flex;
    justify-content: center;
  }
  .DownloadHero_main .mobileHero img {
    max-width: 700px;
  }
  .DownloadHero_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .DownloadHero_text h1 {
    font-size: 24px;
  }
  .DownloadHero_text span {
    font-size: 15px;
  }
}
